import React, { useState } from 'react'
import Webcam from 'react-webcam'

const CameraView = () => {
  const [streaming, setStreaming] = useState(false)
  const webcamRef = React.useRef(null)

  const startVideo = () => {
    setStreaming(true)
  }

  return (
    <div className='video-container'>
      {streaming ? (
        <Webcam
          audio={false}
          ref={webcamRef}
          mirrored={false}
          screenshotFormat='image/jpeg'
          videoConstraints={{
            facingMode: 'environment',
          }}
        />
      ) : (
        <div>
          <button onClick={startVideo} className='streamButton'>
            Start Camera
          </button>
        </div>
      )}{' '}
      <img
        className='logo'
        src='https://ar.magnific.in/images/logo.png'
        alt='Magnific logo'
      />
    </div>
  )
}

export default CameraView
